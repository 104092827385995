import React, { useState } from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share"

import x from "../images/x.png"
import copylink from "../images/copylink.png"

const ShareButtons = ({ url, twitterHandle }) => {
  const [spanKey, setspanKey] = useState(0)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url)
      setspanKey(prevKey => prevKey + 1)
      setCopySuccess(true)
      setSnackbarOpen(true)
      setTimeout(() => {
        setSnackbarOpen(false)
      }, 3000)
    } catch (err) {
      setCopySuccess(false)
      setSnackbarOpen(true)
      setTimeout(() => {
        setSnackbarOpen(false)
      }, 3000)
    }
  }

  return (
    <div className="text-left mb-5" style={{ height: '75px' }}>
      <p>
        <strong>Share this blog on:</strong>
      </p>
      <span title="Share on Facebook">
        <FacebookShareButton url={url} className="mr-2">
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
      </span>
      <span title="Share on Twitter">
        <TwitterShareButton url={url} via={twitterHandle} className="mr-2">
          <img src={x} width={40} height={40} round={true} />
        </TwitterShareButton>
      </span>
      <span title="Share on LinkedIn">
        <LinkedinShareButton url={url} className="mr-2">
          <LinkedinIcon size={40} round={true} />
        </LinkedinShareButton>
      </span>
      <span title="Share on Reddit">
        <RedditShareButton url={url} className="mr-2">
          <RedditIcon size={40} round={true} />
        </RedditShareButton>
      </span>
      <span title="Share on WhatsApp">
        <WhatsappShareButton url={url} className="mr-2">
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
      </span>
      <span title={"Click to Copy Link"} key={spanKey}>
        <img
          src={copylink}
          width={40}
          height={40}
          round={true}
          onClick={copyToClipboard}
          style={{ cursor: "pointer" }}
          className="mr-2"
        />
      </span>
      {snackbarOpen && (
        <div className="snackbar">
          <p>
            {copySuccess ? "Link copied to clipboard!" : "Failed to copy link!"}
          </p>
        </div>
      )}
    </div>
  )
}

export default ShareButtons
