import React from "react"
import { graphql } from "gatsby"

import BlogLayout from "../components/blogLayout"
import SEO from "../components/seo"
import ShareButtons from "../components/socialShare"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  // const { previous, next } = data

  //For the social share buttons
  const title = `${data.markdownRemark.frontmatter.title}`;
  const url = location.href;
  const twitterHandle = "d2itechnology";
  // const tags = props.data.markdownRemark.frontmatter.tags;
  const featuredImage = post.frontmatter.featured_image?.publicURL || ''
  return (
    <BlogLayout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={featuredImage ? {
          width: 1024,
          height: 640,
          src: featuredImage
        }: undefined}
      />
      <header className="masthead">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="post-heading">
                <h1>{post.frontmatter.title}</h1>
                {/* <h2 className="subheading"></h2> */}
                <span className="meta">
                  Posted by <strong>D2i Team</strong> on {post.frontmatter.date}
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <article className="blog-article">
        <div className="container">
          <div className="row">
            <div
              className="col-12"
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></div>
            <div className="col-12">
         
          <ShareButtons title={title} url={url} twitterHandle={twitterHandle}  />
   
            </div>
          </div>
        </div>
      </article>
    </BlogLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featured_image {
            publicURL
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
